import * as React from 'react'

import { CommandDialog } from '#app/components/ui/command'
import { CommandItems } from '#app/utils/command.tsx'
import { useMatches } from '@remix-run/react'
import { cn } from '#app/utils/misc.js'

export function AppCommandDialog() {
	const [open, setOpen] = React.useState(false)

	React.useEffect(() => {
		if (isOnAppPage) return
		const down = (e: KeyboardEvent) => {
			if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				setOpen((open) => !open)
			}
		}

		document.addEventListener('keydown', down)
		return () => document.removeEventListener('keydown', down)
	}, [])

	const matches = useMatches()
	const isOnAppPage = matches.find((m) => m.id === 'routes/_app+/index')

	return (
		<>
			<p className={cn("text-sm text-muted-foreground", isOnAppPage && "invisible")}>
				Press{' '}
				<kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
					<span className="text-xs">Ctrl</span> + J
				</kbd>
			</p>
			<CommandDialog open={open} onOpenChange={setOpen}>
				<CommandItems defaultCommand={''} closeDialog={() => setOpen(false)} />
			</CommandDialog>
		</>
	)
}
