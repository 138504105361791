import * as React from 'react'

import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	NavigationMenuTrigger,
	navigationMenuTriggerStyle,
} from '#app/components/ui/navigation-menu'
import { Link } from '@remix-run/react'
import { cn } from '#app/utils/misc.js'
import { IndianRupee } from 'lucide-react'
import { useRootLoader } from '#app/utils/app.js'

export function AppNavigationMenu() {
	const { isAdmin } = useRootLoader()
	return (
		<NavigationMenu>
			<NavigationMenuList>
				<NavigationMenuItem>
					<NavigationMenuTrigger>Accounting</NavigationMenuTrigger>
					<NavigationMenuContent>
						<ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
							<li className="row-span-4">
								<NavigationMenuLink asChild>
									<a
										className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
										href="/reports/finances"
									>
										<IndianRupee className="h-8 w-8" />
										<div className="mb-2 mt-4 text-lg font-medium">
											Accounting Reports
										</div>
										<p className="text-sm leading-tight text-muted-foreground">
											Complete reports for the school's accounting.
										</p>
									</a>
								</NavigationMenuLink>
							</li>
							<ListItem href="/accounting/payment" title="Make Payment">
								Add payment entry to accounts.
							</ListItem>
							<ListItem href="/accounting/receipt" title="Receive Payment">
								Add receipt entry to accounts.
							</ListItem>
							{/* <ListItem
								href="/accounting/transactions"
								title="Transactions"
							>
								View all transactions and entries.
							</ListItem> */}
							<ListItem
								href="/accounting/chart-of-accounts"
								title="Chart of Accounts"
								className="col-span-2"
							>
								{isAdmin
									? 'View the chart of accounts. And create new accounts and account groups.'
									: 'View the chart of accounts.'}
							</ListItem>
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
				<NavigationMenuItem>
					<NavigationMenuTrigger>Reports</NavigationMenuTrigger>
					<NavigationMenuContent>
						<ul
							className={cn(
								'grid gap-3 p-4',
								isAdmin ? 'md:grid-cols-2 w-[400px] md:w-[500px] lg:w-[600px]' : 'grid-cols-1 w-[200px] md:w-[250px] lg:w-[300px]',
							)}
						>
							<ListItem
								href="/reports/fees"
								title="Fee Reports"
								className="col-span-2"
							>
								Complete reports for fee related transactions.
							</ListItem>
							{isAdmin && (
								<ListItem
									href="/reports/finances"
									title="Financial Reports"
									className="col-span-2"
								>
									Complete reports of the school's financial position.
								</ListItem>
							)}
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
				{isAdmin && (
					<NavigationMenuItem>
						<Link to="/settings">
							<NavigationMenuLink className={navigationMenuTriggerStyle()}>
							Settings
						</NavigationMenuLink>
						</Link>
					</NavigationMenuItem>
				)}
				<NavigationMenuItem>
					<Link to="/">
						<NavigationMenuLink className={navigationMenuTriggerStyle()}>
							Home
						</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>
			</NavigationMenuList>
		</NavigationMenu>
	)
}

const ListItem = React.forwardRef<
	React.ElementRef<'a'>,
	React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, href, ...props }, ref) => {
	return (
		<li>
			<NavigationMenuLink asChild>
				<Link
					ref={ref}
					to={href ?? ''}
					className={cn(
						'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
						className,
					)}
					{...props}
				>
					<div className="text-sm font-medium leading-none">{title}</div>
					<p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
						{children}
					</p>
				</Link>
			</NavigationMenuLink>
		</li>
	)
})
ListItem.displayName = 'ListItem'
