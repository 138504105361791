import { Link } from '@remix-run/react'
import { buttonVariants } from './ui/button.tsx'

import Logo from './logo.tsx'
import { AppNavigationMenu } from './navigation.tsx'
import { AppCommandDialog } from './command-dialog.tsx'
import UserDropdown from './user-dropdown.tsx'
import { ThemeSwitch } from '../routes/resources+/theme-switch.tsx'
import { useRootLoader } from '#app/utils/app.js'

export default function AppHeader({
	commandDialog = true,
	adminPortal = true,
	navigation = true,
}: {
	commandDialog?: boolean
	adminPortal?: boolean
	navigation?: boolean
}) {
	const rootData = useRootLoader()
	const isAdmin = rootData.isAdmin

	return (
		<header className="container py-6">
			<nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
				<Logo />
				{navigation && rootData.user ? <AppNavigationMenu /> : null}

				<div className="flex items-center gap-4">
					{rootData.user ? (
						<>
							{commandDialog ? <AppCommandDialog /> : null}
							{isAdmin ? (
								<Link
									to={'/admin'}
									className={buttonVariants({ variant: 'default' })}
								>
									Admin Portal
								</Link>
							) : null}
							<UserDropdown />
						</>
					) : null}
					<ThemeSwitch userPreference={rootData.requestInfo.userPrefs.theme} />
				</div>
			</nav>
		</header>
	)
}
